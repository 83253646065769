
import "./checkout-submit-notification-v1"
import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutMultiplePaymentsV2 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

devices = { 
    'payment-card': undefined,
    paypal: undefined,
    'apple-pay': undefined,
  }
  CARD_FIELDS = ['number', 'cvv', 'date']

  mount () {
    this.enabledPayments = this.enabledPayments ?? []
    this.cleanupApplePayOption()
    // TODO: make apple pay disabled when summary state. is != OK
    const form = document.querySelector('#cfAR')
     form.querySelectorAll('input[data-rebilly]').forEach((cfARInput) => {
      const dataRebillyAttribute = cfARInput.getAttribute('data-rebilly')
      const dataCfFormField = cfARInput.getAttribute('data-cf-form-field')
      const pageInputField = document.querySelector('input' + '[name="' + dataCfFormField + '"]')
      if (!pageInputField) {
        cfARInput?.removeAttribute('data-rebilly')
      } else {
        pageInputField?.setAttribute('data-rebilly', dataRebillyAttribute)
      }
    })

    Checkout.computed.modeLeaveEnterEvent.subscribe(ev => {
      const { leave, next, enter, previous } = ev
      const paymentState = Checkout.store.payment.state.get()
      if (paymentState == Checkout.PaymentStates.INITIALIZED) {
        if (leave && leave === this.mode) {
          this.unmountAllPaymentMethods()
        } 
        if (enter === this.mode && previous) {
          this.mountRebillyElement()
        }
      }
    })

    Checkout.store.payment.state.listen((state) => {
      const checkoutMode = Checkout.store.checkout.mode.get()
      switch (state) {
        case Checkout.PaymentStates.LOADING: {
          if (this.mode == 'guest') this.showLoader()
          break
        }
        case Checkout.PaymentStates.INITIALIZED: {
          if (this.mode == 'guest') this.hideLoader()
          this.element.setAttribute('data-initialized', 'true')
          this.unmountAllPaymentMethods()
          if (this.mode == Checkout.store.checkout.mode.get()) this.mountRebillyElement()
          break
        }
      }
    })

    Checkout.computed.paymentErrors.subscribe((errors) => {
      const errorWrapper = this.element.querySelector('.elCheckoutFormErrors')
      errorWrapper.classList.add('elHide')
      this.element.classList.remove('hasPaymentError')
      if (Checkout.utils.hasErrors(errors)) {
        const isFocused = this.element.querySelector('.payment-card-fields-container-focus')
        const isMobile = this.element.clientWidth <= 770
        if (!isFocused || isMobile) {
          this.element.classList.add('hasPaymentError')
        }
        const fieldsErrors = errors.fields
        if (fieldsErrors) {
          const errors = []
          Object.entries(fieldsErrors).forEach(([field, error]) => {
            const {message} = error
            errors.push(message)
          })
          if (errors.length) {
            const listErrors = `<ul>${errors.map(err => `<li>${err}</li>`).join('')}</ul>`
            errorWrapper.innerHTML = listErrors
            errorWrapper.classList.remove('elHide')
          } else {
            errorWrapper.classList.add('elHide')
          }
        }
      }
    })

    Checkout.store.payment.type.listen((type) => {
      const paymentState = Checkout.store.payment.state.get()
      this.showPaymentType(type)
      this.unmountAllPaymentMethods()

      // NOTE: once paypal payment method is approved the only way to get rid of it is refreshing the window
      if (type == 'paypal' && Checkout.store.payment.paypal.state.get().state == Checkout.PaypalStates.PAYMENT_METHOD_APPROVED) return

      if (this.mode == Checkout.store.checkout.mode.get()) {
        if (paymentState === Checkout.PaymentStates.INITIALIZED) {
          this.mountRebillyElement()
        }
      }
    })

    Checkout.store.submitting.listen((value) => {
      const paymentType = Checkout.store.payment.type.get()
      const failedSubmit = value.state == Checkout.SubmittingStates.ERROR
      if (failedSubmit && paymentType == 'apple-pay' && this.mode == Checkout.store.checkout.mode.get()) {
        this.unmountAllPaymentMethods()
        this.mountRebillyElement()
      }
    })

    if (this.enabledPayments.includes('paypal')) {
      this.addPaypalEventListeners()
    }
    this.addPaymentEventListeners()
  }

  cleanupApplePayOption() {
    const applePayIndex = this.enabledPayments.findIndex((v) => v == 'apple-pay')
    if (!window.ApplePaySession && applePayIndex >= 0) {
      this.enabledPayments.splice(applePayIndex, 1)
    }

    const applePayElement = this.element.querySelector('[data-payment-type="apple-pay"]')
    if (!applePayElement) return

    if (!window.ApplePaySession || !this.enabledPayments.includes('apple-pay')) {
      const isApplePayActive = applePayElement.getAttribute('data-active') == 'true'
      if (isApplePayActive && this.enabledPayments[0]) {
        this.showPaymentType(this.enabledPayments[0])
      }
      applePayElement.remove()
      if (this.enabledPayments.length <= 1) {
        this.element.querySelector('.pai-payment-methods')?.remove()
      }
    } else {
      applePayElement.classList.remove('elHide')
    }
  }

  showLoader() {
    this.element.querySelector('.elPAILoader').dataset.loader = true
  }
  hideLoader() {
    this.element.querySelector('.elPAILoader').dataset.loader = false
  }

  addPaypalEventListeners() {
    Checkout.store.payment.paypal.state.listen((paypalState) => {
      switch (paypalState.state) {
        case Checkout.PaypalStates.IDLE: {
          this.paypalPreventClick()
          this.paypalInitializeContent()
          break;
        }
        case Checkout.PaypalStates.INITIALIZED: {
          this.paypalAllowClick()
          this.paypalInitializeContent()
          break;
        }
        case Checkout.PaypalStates.ERROR: {
          this.paypalPreventClick()
          switch (paypalState.code) {
            case Checkout.ErrorTypes.PAYPAL_DECLINED_ERROR: {
              const paypalStatus = this.getPaypalContent().querySelector('.paypal-status')
              paypalStatus.setAttribute('data-type', 'error')
              paypalStatus.innerText = 'Paypal declined'
              break;
            }
            case Checkout.ErrorTypes.PAYPAL_CUSTOM_ERROR: {
              const paypalStatus = this.getPaypalContent().querySelector('.paypal-status')
              paypalStatus.setAttribute('data-type', 'error')
              paypalStatus.innerText = paypalState.message
              break;
            }
          }
          break;
        }
        case Checkout.PaypalStates.PAYMENT_METHOD_APPROVED: {
          this.getPaypalContent().querySelector('.paypal-content-button-container').classList.add('elHide')
          const paypalStatus = this.getPaypalContent().querySelector('.paypal-status')
          paypalStatus.setAttribute('data-type', 'success')
          paypalStatus.innerHTML = '<i class="fas fa-check"></i> Your payment is configured'
          break;
        }
      }
    })
  }

  getPaypalContent() {
    return this.element.querySelector('.paypal-content')
  }
  paypalInitializeContent() {
    this.getPaypalContent().querySelector('.paypal-status').innerHTML = ''
    this.getPaypalContent().querySelector('.paypal-content-button-container').classList.remove('elHide')
  }
  paypalPreventClick() {
    this.getPaypalContent().querySelector('.elSpinnerWrapper').dataset.loader = true
  }
  paypalAllowClick() {
    this.getPaypalContent().querySelector('.elSpinnerWrapper').dataset.loader = false
  }

  showPaymentType(type) {
    const paymentFields = this.element.querySelectorAll('.pai-payment-field')
    paymentFields.forEach((element) => {
      element.dataset.active = element.dataset.paymentType == type
    })
    const paymentContents = this.element.querySelectorAll('.pai-payment-content')
    paymentContents.forEach((element) => {
      element.dataset.active = element.dataset.paymentType == type
    })
  }

  addPaymentEventListeners() {
    const paymentFields = this.element.querySelectorAll('.pai-payment-field')

    paymentFields.forEach((element) => {
      element.addEventListener('click', (evt) => {
        evt.preventDefault();

        const paymentType = element.dataset.paymentType
        if (Checkout.store.payment.type.get() == paymentType) return

        // NOTE: in order to unmount payment-card we need to first check it was properly mounted.
        if (
            Checkout.store.payment.type.get() == 'payment-card' &&
            !Object.values(this.devices['payment-card'] ?? {}).every((v) => v?.element?.mounted)
        ) return

        // NOTE: once paypal payment method is approved the only way to get rid of it is refreshing the window
        if (this.enabledPayments.includes('paypal') && Checkout.store.payment.paypal.state.get().state != Checkout.PaypalStates.PAYMENT_METHOD_APPROVED) {
          Checkout.store.payment.paypal.state.set({ state: Checkout.PaypalStates.IDLE })
        }

        Checkout.store.payment['payment-card'].events.set({})
        Checkout.store.payment.type.set(paymentType)
      })
    })
  }

  unmountPaymentMethod(type) {
    switch (type) {
      case 'apple-pay': {
        if (!this.devices['apple-pay']) break
        this.devices['apple-pay'].unmount()
        this.devices['apple-pay'] = undefined
        break
      }
      case 'paypal': {
        if (!this.devices.paypal) break
        this.devices.paypal.unmount()
        this.devices.paypal = undefined
        break
      }
      case 'payment-card': {
        if (!this.devices['payment-card']) break

        ["number", "cvv", "date"].forEach((key) => {
          this.devices['payment-card'][key].element.mounted && this.devices['payment-card'][key].destroy()
        })
        this.devices['payment-card'] = undefined
        break
      }
    }
  }

  unmountPaymentMethods(types) {
    types.forEach((type) => {
      this.unmountPaymentMethod(type)
    })
  }

  unmountAllPaymentMethods() {
    this.unmountPaymentMethods(this.enabledPayments)
  }

  mountRebillyElement() {
    const Rebilly = globalThis.Rebilly
    const paymentType = Checkout.store.payment.type.get()

    if (paymentType == 'apple-pay' && !this.devices['apple-pay']) {
      this.devices['apple-pay'] = Rebilly.applePay.mount(`[data-wrapper-checkout-state="${this.mode}"] .apple-pay-button`)
    }

    if (paymentType == 'payment-card' && !this.devices['payment-card']?.number) {
      const paymentCardWrapper = this.element.querySelector('.payment-card-fields-container')
      this.devices['payment-card'] = {}
      let lastEvent

      const mountNameMapper = {
        number: 'cardNumber',
        cvv: 'cardCvv',
        date: 'cardExpiration',
      }
      this.CARD_FIELDS.forEach((key) => {
        const rebillyField = Rebilly.card.mount(
          `[data-wrapper-checkout-state="${this.mode}"] .payment-card-${key}-mount`, mountNameMapper[key]
        )
        const fieldElement = rebillyField.element.element.querySelector('.rebilly-framepay')
        rebillyField.on('focus', (ev) => {
          this.element.classList.remove('hasPaymentError')
          fieldElement.classList.add(`payment-card-field-focus`)
          paymentCardWrapper.classList.add('payment-card-fields-container-focus')
          Checkout.store.payment['payment-card'].events.setKey(key, undefined)
          lastEvent = null
        })
        rebillyField.on('change', (ev) => {
          lastEvent = ev
        })
        rebillyField.on('blur', (ev) => {
          fieldElement.classList.remove(`payment-card-field-focus`)
          paymentCardWrapper.classList.remove('payment-card-fields-container-focus')
          Checkout.store.payment['payment-card'].events.setKey(key, lastEvent)
        })
        this.devices['payment-card'][key] = rebillyField
      })
    } else if (paymentType == 'paypal') {
      this.devices.paypal = Rebilly.paypal.mount(`[data-wrapper-checkout-state="${this.mode}"] .paypal-mount`, { extraData: Checkout.PaypalCallbacks })
    }
  }



}

window["CheckoutMultiplePaymentsV2"] = CheckoutMultiplePaymentsV2

