import '../dist/checkout-v2.css'
import '../blueprints/checkout-globals-v1.ts'
import '../blueprints/checkout-saved-multiple-payments-preview-v1.ts'
import '../blueprints/checkout-configuration-error-v1.ts'
import '../blueprints/checkout-tos-v1.ts'
import '../blueprints/checkout-submit-notification-v1.ts'
import '../blueprints/checkout-coupon-form-v1.ts'
import '../blueprints/checkout-threeds-frame-v1.ts'
import '../blueprints/checkout-order-summary-v1.ts'
import '../blueprints/checkout-address-form-v1.ts'
import '../blueprints/checkout-billing-address-v1.ts'
import '../blueprints/checkout-shipping-address-v1.ts'
import '../blueprints/checkout-multi-step-v2.ts'
import '../blueprints/checkout-login-v2.ts'
import '../blueprints/checkout-product-card-v2.ts'
import '../blueprints/checkout-product-card-bump-v2.ts'
import '../blueprints/checkout-product-select-v2.ts'
import '../blueprints/checkout-product-select-bump-v2.ts'
import '../blueprints/checkout-shipping-profile-select-v1.ts'
import '../blueprints/checkout-multiple-payments-v2.ts'
import '../blueprints/checkout-contact-form-v1.ts'
import '../blueprints/checkout-saved-address-form-v1.ts'
import '../blueprints/checkout-saved-billing-address-v1.ts'
import '../blueprints/checkout-saved-contact-details-v1.ts'
import '../blueprints/checkout-saved-multiple-payments-v1.ts'
import '../blueprints/checkout-loading-v1.ts'
import '../blueprints/checkout-order-details-content-v1.ts'
import '../blueprints/checkout-order-details-v1.ts'
import '../blueprints/checkout-submit-button-v1.ts'
import '../blueprints/checkout-v2.ts'
