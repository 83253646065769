import { CheckoutSummary } from './checkout-summary'
import { CheckoutSubmit } from './checkout-submit'
import { UpdateTransactionData } from './checkout-submit'
import { CheckoutCart } from 'src/Elements/Checkout/V2/types'
export class CheckoutDigitalWallet {
  static oldShipping
  static oldBilling
  static oldBillingSameAsShipping
  static initialize(): void {
    globalThis.Rebilly.on('token-ready', (data, extraData) => {
      const paymentType = globalThis.Checkout.store.payment.type.get()
      globalThis.Checkout.store.payment[paymentType].token.set(data.id)
      CheckoutSummary.skipOrderSummaryUpdateWithCb(() => {
        if (paymentType == 'apple-pay') {
          const shippingAddress = extraData?.shippingDetails?.address
          const billingAddress = data.billingAddress
          const { emailAddress: shippingEmail, familyName: shippingLN, givenName: shippingFN } = shippingAddress ?? {}
          const {
            firstName: billingFN,
            lastName: billingLN,
            // address: billingAddr,
            // address2: billingAddr2,
          } = billingAddress

          if (globalThis.Checkout.computed.hasPhysicalProducts.get()) {
            globalThis.Checkout.store.shipping.set({
              address: shippingAddress.addressLines?.[0],
              address_2: shippingAddress.addressLines?.[1],
              country: shippingAddress.countryCode.toUpperCase(),
              city: shippingAddress.locality,
              zip: shippingAddress.postalCode,
              state: shippingAddress.administrativeArea,
            })
          }
          globalThis.Checkout.store.billing.set({
            address: billingAddress.address,
            address_2: billingAddress.address2,
            country: billingAddress.country.toUpperCase(),
            city: billingAddress.city,
            zip: billingAddress.postalCode,
            state: billingAddress.region,
          })
          let contact
          if (globalThis.Checkout.computed.useDigitalWalletForUpdatingContactStore.get()) {
            contact = {
              email: shippingEmail,
              first_name: [shippingFN, billingFN].find(Boolean),
              last_name: [shippingLN, billingLN].find(Boolean),
              phone_number: globalThis.Checkout.store.contact.get().phone_number,
            }
          }
          const payload = CheckoutSubmit.buildPayloadFromStore({ contact })
          CheckoutSubmit.submit(payload)
        }
      })
    })
    globalThis.Rebilly.on('error', (err) => {
      if (err.code == 'apple-pay-error') {
        // NOTE: this is captured when apple pay modal is closed
        // This clears all data appel pay modal started with
        this.#reset()
      }
    })
    globalThis.Rebilly.on('shipping-address-changed', (shippingAddress, updateShippingOptions) => {
      CheckoutSummary.skipOrderSummaryUpdateWithCb(() => {
        globalThis.Checkout.store.shipping.set({
          country: shippingAddress.countryCode.toUpperCase(),
          city: shippingAddress.locality,
          zip: shippingAddress.postalCode,
          state: shippingAddress.administrativeArea,
        })
      })
      this.#updateRebillyWithCallback(updateShippingOptions)
    })
    globalThis.Rebilly.on('shipping-option-changed', (selectedShippingOption, updateTransaction) => {
      CheckoutSummary.skipOrderSummaryUpdateWithCb(() => {
        const shippingOptions = globalThis.Checkout.store.shippingOptions.get()
        const shippingOption = shippingOptions.find(
          (s) => CheckoutSubmit.generateShippingOptionId(s) == selectedShippingOption.id
        )
        globalThis.Checkout.store.shippingOption.set(shippingOption)
      })
      this.#updateRebillyWithCallback(updateTransaction)
    })
    globalThis.Rebilly.on('billing-address-changed', (billingAddress, updateTransaction) => {
      if (billingAddress) {
        CheckoutSummary.skipOrderSummaryUpdateWithCb(() => {
          globalThis.Checkout.store.billing.set({
            country: billingAddress.countryCode.toUpperCase(),
            city: billingAddress.locality,
            zip: billingAddress.postalCode,
            state: billingAddress.administrativeArea,
          })
        })
      }
      this.#updateRebillyWithCallback(updateTransaction)
    })
  }

  static #reset(): void {
    globalThis.Checkout.store.shippingOptions.set([])
    globalThis.Checkout.store.shipping.set({})
    globalThis.Checkout.store.billing.set({})
    globalThis.Checkout.store.shippingOption.set(null)
  }

  static listen(): void {
    let previousIsNewDigitalWalletPayment = globalThis.Checkout.computed.isNewDigitalWalletPayment.get()
    globalThis.Checkout.computed.isNewDigitalWalletPayment.subscribe((isNewDigitalWalletPayment) => {
      if (isNewDigitalWalletPayment) {
        this.clearAddresses()
      } else if (previousIsNewDigitalWalletPayment && !isNewDigitalWalletPayment) {
        this.restoreAddresses()
      }
      previousIsNewDigitalWalletPayment = isNewDigitalWalletPayment
    })
  }

  static #updateRebillyWithCallback(updateCallback: (transactionData: UpdateTransactionData) => void): void {
    CheckoutSummary.fetchOrderPreview({ callRebillyUpdate: false }).then(() => {
      const summary = globalThis.Checkout.store.summary.get()
      const cart = globalThis.Checkout.computed.checkoutCart.get() as CheckoutCart
      const shippingEnabled = globalThis.Checkout.utils.hasPhysicalProducts()
      const shippingOptions = globalThis.Checkout.store.shippingOptions.get()
      const transactionData = CheckoutSubmit.buildDigitalWalletTransactionData(
        summary.data,
        cart,
        shippingEnabled,
        shippingOptions
      )
      updateCallback(transactionData)
    })
  }

  static restoreAddresses(): void {
    globalThis.Checkout.store.shipping.set(this.oldShipping)
    globalThis.Checkout.store.billing.set(this.oldBilling)
    globalThis.Checkout.store.billingSameAsShipping.set(this.oldBillingSameAsShipping)
  }

  static clearAddresses(): void {
    this.oldShipping = globalThis.Checkout.store.shipping.get()
    this.oldBilling = globalThis.Checkout.store.billing.get()
    this.oldBillingSameAsShipping = globalThis.Checkout.store.billingSameAsShipping.get()

    globalThis.Checkout.store.shipping.set({})
    globalThis.Checkout.store.billing.set({})
    globalThis.Checkout.store.billingSameAsShipping.set(false)
  }
}
